import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ResourceCard = makeShortcode("ResourceCard");
const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`The `}<inlineCode parentName="p">{`<ResourceCard>`}</inlineCode>{` component should be wrapped with a `}<inlineCode parentName="p">{`<Column>`}</inlineCode>{` inside of
`}<inlineCode parentName="p">{`<Row className="resource-card-group">`}</inlineCode>{`. This allows the correct border
placement between a group of cards.`}</p>
    </PageDescription>
    <h2>{`Example`}</h2>
    <Row className="resource-card-group" mdxType="Row">
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ResourceCard subTitle="With subtitle" title="Title" aspectRatio="2:1" actionIcon="arrowRight" href="https://gatsby-theme-carbon.now.sh" mdxType="ResourceCard">
          <img {...{
            "src": "/18f3a3dc6c5ae9221446f35a437b2780/adobe-icon.svg",
            "alt": "Adobe Acrobat icon"
          }}></img>
  </ResourceCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ResourceCard subTitle="Only subtitle" actionIcon="download" aspectRatio="2:1" href="https://gatsby-theme-carbon.now.sh" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "267px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAC4jAAAuIwF4pT92AAACSUlEQVQ4y62US2gTQRjHJy8v4kGrB0E9CeKLFouiB4uX+kpPHnyh2VQ3q0VKPUilUBUaomhBFC8erGANqNRnoTfPIsVHMRJURK1QsWiogSbaNfv9/WYmr8KmMdaFPzPz7e5v/t/MNyMgImmWw/r9LyLd2iyw3oh857+IhJmRQJqNqNSX0B+ibDAbFRhpl5RN3foswGu5xI/k42b5u0pAs6w9wAoBHss9XhybMwGlDgNz2oBtl4CNZ3ncWnIk2x2XgU3n9HdVHSo3YWDpSSA7BfU0Xcg7Ogh0P9SxF6M8Nvj7qsCIdrSMgeNp/fPnlF63Lb0oPk8/6IlrAqYmASINGHoFJL+UgMMfawQu6QQmfwGj7O7GkxLo1jDg8CTP/zplqwTM8BqOTQDz2nXagyPA6jMa/OxTjWtYcCjTFoeAdVFg8QnQqtOKR7yGJFPmXScPFzWLW45FJtwLO3BUl8b6mN4Q/lkCaO4xYEMMtPKUnki08tErKCxL6ecMhR3KywQpJ5aqPVIxA7n5HUT1UcLaHmBNj436KGh598vKR8+rjx6pW0THHfnO14YxsZ/eN3YRIrdBRr+N8E04Rn8i2/t4UfnlQC7XkZLsK5iw8N1r0F2xlZINvM6huE37rsPZ0/c6u/l8nd0xIKoCp8E43cFAC90RzXjb0MnO4sgxLNNyZcHUrqsit/uaX1SCTYda+CZhviAeBIIYEM25d41dgBFPfG2K1RVgzt4+5XC8msMUb4KE3ffvxCN/0Lnn2Y6RFe1JXEwsxPGhIkzqD93x76HTk32ZAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Mural icon",
              "title": "Mural icon",
              "src": "/static/cc31ca8ce56236accbb5ef95924419f3/ba499/mural-icon.png",
              "srcSet": ["/static/cc31ca8ce56236accbb5ef95924419f3/ba499/mural-icon.png 267w"],
              "sizes": "(max-width: 267px) 100vw, 267px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ResourceCard subTitle="Alternate color" title="Dark" aspectRatio="2:1" color="dark" actionIcon="email" href="https://gatsby-theme-carbon.now.sh" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "95%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAAAsTAAALEwEAmpwYAAADaElEQVQ4y4WTT2xUdRDHv2/fKlRTjRBIvHIyUaPeNMYEEw8E8GRCYjggB9D+ldTyp0cxekBjoAFUojEkCmltt9228eABE02oVJLGthxoosFCZbu7bIuWuuzrm+E78163hYP+kslv3m/m+3kz83sPmgPEbAiBDAORboGMZzfIb+FTMtm4NR7HmzL9YodMv/ye+1ONr3psPLuxpptdI3kE2s+dlsCGkdFvAP0aG+RS+Es8lV2UyXXVeAIqU4+pzHXTTtJ/XP3MYpZzKRzTs9ik36YMA3qFPwSB9tDvQ79cDDW+so7CgML1KoVPY134MdKFC5EUjscy+YhYzHJkNFRq8jTIaAYrwNAO+NBJUxl7qBZPhbFMhCJ/HRXCRCp52mDi3/xQZCIrnsNctqnUdRnMWNC89/6SBdg+gWEcT3K/ccgASpBqZdjN/YULjHWJ5/yaEcmxE9P24xVjQSfQoAOYJl25L8tlBmfbRG//pDI/orLwPSGJmW9nHps9IJZrmlT7u17Go1g+h764Fxr3BjXb5eobordGRIvnVYo9qqUe39f6FmOOyvQuSbWR7WTlUenE6XI79Na7iMqtkOqXjaIjDSIDDaqDDSqDyb7W9xhz7n7VKOU2iGuN0YkzuPMJUGrGd6UWaPEd1OYP+zyEQxbpc9+G7pbOynyPzx+h5m3UTEvGwFI3Z3hzDzD/PrKlVoyyQodWP6NoiEAT5tLLsjnl0pcxVv2cEOaahtqxShceLrxFIB9CGkpt2MzgtVITlFVGXkkugcpqdeJnBLO6yHKpmaH+SWeQBZa7Cm3Fs0xYZJX67yne3lAdsrY6rZ7GsuUwd4ma553Rwi5bgATYvHrA4W6zN1cOOSBeAdXb55nN2atrx846rCnhWKvgYFHaex90v1d5ksChNZdD3yr36trRvFJIcTf3/a5Lljnlg7QD/pZMgXCKPuInZaDI5pbOM6oc9Js9Nrc3yS2ZtiMp7L5lBx5sR1DYBVx/HZjbh3NLx72ymtnSCcL2off6diB+xjWBzb78IKwOba1b8MdWYADIcJY/xz32N3Cuh3FxDMhee81bzaTz++9lwGJTIvhzG3BlC56onsEMbfbq09g4syOFJTm4+8X/ACsd6c3Tbh9F+M8x4E43nuNf8MLix8DfH/ATs4trSWb34LoHtkAcmzkyiKUAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Sketch icon",
              "title": "Sketch icon",
              "src": "/static/28b17f4b368ca58d4d47e5136b85f0d3/59afc/sketch-icon.png",
              "srcSet": ["/static/28b17f4b368ca58d4d47e5136b85f0d3/59afc/sketch-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ResourceCard title="Disabled card" aspectRatio="2:1" disabled href="https://gatsby-theme-carbon.now.sh" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "95%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAAAsTAAALEwEAmpwYAAADaElEQVQ4y4WTT2xUdRDHv2/fKlRTjRBIvHIyUaPeNMYEEw8E8GRCYjggB9D+ldTyp0cxekBjoAFUojEkCmltt9228eABE02oVJLGthxoosFCZbu7bIuWuuzrm+E78163hYP+kslv3m/m+3kz83sPmgPEbAiBDAORboGMZzfIb+FTMtm4NR7HmzL9YodMv/ye+1ONr3psPLuxpptdI3kE2s+dlsCGkdFvAP0aG+RS+Es8lV2UyXXVeAIqU4+pzHXTTtJ/XP3MYpZzKRzTs9ik36YMA3qFPwSB9tDvQ79cDDW+so7CgML1KoVPY134MdKFC5EUjscy+YhYzHJkNFRq8jTIaAYrwNAO+NBJUxl7qBZPhbFMhCJ/HRXCRCp52mDi3/xQZCIrnsNctqnUdRnMWNC89/6SBdg+gWEcT3K/ccgASpBqZdjN/YULjHWJ5/yaEcmxE9P24xVjQSfQoAOYJl25L8tlBmfbRG//pDI/orLwPSGJmW9nHps9IJZrmlT7u17Go1g+h764Fxr3BjXb5eobordGRIvnVYo9qqUe39f6FmOOyvQuSbWR7WTlUenE6XI79Na7iMqtkOqXjaIjDSIDDaqDDSqDyb7W9xhz7n7VKOU2iGuN0YkzuPMJUGrGd6UWaPEd1OYP+zyEQxbpc9+G7pbOynyPzx+h5m3UTEvGwFI3Z3hzDzD/PrKlVoyyQodWP6NoiEAT5tLLsjnl0pcxVv2cEOaahtqxShceLrxFIB9CGkpt2MzgtVITlFVGXkkugcpqdeJnBLO6yHKpmaH+SWeQBZa7Cm3Fs0xYZJX67yne3lAdsrY6rZ7GsuUwd4ma553Rwi5bgATYvHrA4W6zN1cOOSBeAdXb55nN2atrx846rCnhWKvgYFHaex90v1d5ksChNZdD3yr36trRvFJIcTf3/a5Lljnlg7QD/pZMgXCKPuInZaDI5pbOM6oc9Js9Nrc3yS2ZtiMp7L5lBx5sR1DYBVx/HZjbh3NLx72ymtnSCcL2off6diB+xjWBzb78IKwOba1b8MdWYADIcJY/xz32N3Cuh3FxDMhee81bzaTz++9lwGJTIvhzG3BlC56onsEMbfbq09g4syOFJTm4+8X/ACsd6c3Tbh9F+M8x4E43nuNf8MLix8DfH/ATs4trSWb34LoHtkAcmzkyiKUAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Sketch icon",
              "title": "Sketch icon",
              "src": "/static/28b17f4b368ca58d4d47e5136b85f0d3/59afc/sketch-icon.png",
              "srcSet": ["/static/28b17f4b368ca58d4d47e5136b85f0d3/59afc/sketch-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
    </Row>
    <h2>{`Code`}</h2>
    <Title mdxType="Title">Group</Title>
    <pre><code parentName="pre" {...{
        "className": "language-mdx",
        "metastring": "path=components/ResourceCard/ResourceCard.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/ResourceCard",
        "path": "components/ResourceCard/ResourceCard.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/ResourceCard"
      }}>{`<Row className="resource-card-group">
  <Column colMd={4} colLg={4} noGutterSm>
    <ResourceCard
      subTitle="Carbon Design System"
      href="https://www.carbondesignsystem.com">


![Github icon](/images/github-icon.png)

    </ResourceCard>

  </Column>
  <Column colMd={4} colLg={4} noGutterSm>
    <ResourceCard
      subTitle="Carbon Design System"
      href="https://www.carbondesignsystem.com">


![Github icon](/images/github-icon.png)

    </ResourceCard>

  </Column>
</Row>

`}</code></pre>
    <Title mdxType="Title">With title</Title>
    <pre><code parentName="pre" {...{
        "className": "language-mdx",
        "metastring": "path=components/ResourceCard/ResourceCard.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/ResourceCard",
        "path": "components/ResourceCard/ResourceCard.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/ResourceCard"
      }}>{`<Column colMd={4} colLg={4} noGutterSm>
  <ResourceCard
    subTitle="With subtitle"
    title="Title"
    aspectRatio="2:1"
    actionIcon="arrowRight"
    href="https://gatsby-theme-carbon.now.sh">


![Adobe Acrobat icon](/images/adobe-icon.svg)

  </ResourceCard>
</Column>

`}</code></pre>
    <Title mdxType="Title">Only subtitle</Title>
    <pre><code parentName="pre" {...{
        "className": "language-mdx",
        "metastring": "path=components/ResourceCard/ResourceCard.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/ResourceCard",
        "path": "components/ResourceCard/ResourceCard.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/ResourceCard"
      }}>{`<Column colMd={4} colLg={4} noGutterSm>
  <ResourceCard
    subTitle="Only subtitle"
    actionIcon="download"
    aspectRatio="2:1"
    href="https://gatsby-theme-carbon.now.sh">


![Mural icon](/images/mural-icon.png)

  </ResourceCard>
</Column>

`}</code></pre>
    <Title mdxType="Title">Dark</Title>
    <pre><code parentName="pre" {...{
        "className": "language-mdx",
        "metastring": "path=components/ResourceCard/ResourceCard.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/ResourceCard",
        "path": "components/ResourceCard/ResourceCard.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/ResourceCard"
      }}>{`<Column colMd={4} colLg={4} noGutterSm>
  <ResourceCard
    subTitle="Alternate color"
    title="Dark"
    aspectRatio="2:1"
    color="dark"
    actionIcon="email"
    href="https://gatsby-theme-carbon.now.sh">


![Sketch icon](/images/sketch-icon.png)

  </ResourceCard>
</Column>

`}</code></pre>
    <Title mdxType="Title">Disabled</Title>
    <pre><code parentName="pre" {...{
        "className": "language-mdx",
        "metastring": "path=components/ResourceCard/ResourceCard.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/ResourceCard",
        "path": "components/ResourceCard/ResourceCard.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/ResourceCard"
      }}>{`<Column colMd={4} colLg={4} noGutterSm>
  <ResourceCard
    title="Disabled card"
    aspectRatio="2:1"
    disabled
    href="https://gatsby-theme-carbon.now.sh"
  />
</Column>
`}</code></pre>
    <h3>{`Props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`propType`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Use 32x32 image as child, will display in bottom left of card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`href`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set url for card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`subTitle`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Smaller title`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Large title`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`actionIcon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`launch`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Action icon, default is launch, options are `}<inlineCode parentName="td">{`launch`}</inlineCode>{`, `}<inlineCode parentName="td">{`arrowRight`}</inlineCode>{`, `}<inlineCode parentName="td">{`download`}</inlineCode>{`, `}<inlineCode parentName="td">{`disabled`}</inlineCode>{`, `}<inlineCode parentName="td">{`email`}</inlineCode>{`, `}<inlineCode parentName="td">{`calendar`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`aspectRatio`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2:1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set card aspect ratio, default is 2:1, options are 1:1, 16:9, 4:3`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`light`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set to `}<inlineCode parentName="td">{`dark`}</inlineCode>{` for dark background`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`disabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set for disabled card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Add custom class name`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      