import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Accordion = makeShortcode("Accordion");
const AccordionItem = makeShortcode("AccordionItem");
const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`The `}<inlineCode parentName="p">{`<Accordion>`}</inlineCode>{` and `}<inlineCode parentName="p">{`<AccordionItem>`}</inlineCode>{` components are used together to display
a list of content sections that can be toggled open by clicking the respective
title of each section.`}</p>
    </PageDescription>
    <h2>{`Example`}</h2>
    <Accordion mdxType="Accordion">
      <AccordionItem title="Title 1" mdxType="AccordionItem">
        <p>{`In ac nisi ut mauris venenatis blandit a et ante. Mauris eu congue velit, eget
dictum diam. Etiam sed turpis quis ligula interdum lobortis eu et libero.
Praesent nec eros sit amet elit tempor egestas cursus non nulla. Vestibulum
dictum luctus lorem in rhoncus.`}</p>
        <p>{`Nullam vestibulum blandit libero, ac tempus felis tristique id. Aliquam rhoncus
vestibulum dui eu dictum. Morbi congue purus eu libero sodales, nec sollicitudin
ligula tempor.`}</p>
        <ul>
          <li parentName="ul">{`list item`}</li>
          <li parentName="ul">{`list item`}</li>
          <li parentName="ul">{`list item`}</li>
        </ul>
      </AccordionItem>
  <AccordionItem title="Title 2" mdxType="AccordionItem">Content Section</AccordionItem>
  <AccordionItem title="Title 3" mdxType="AccordionItem">Content Section</AccordionItem>
    </Accordion>
    <h2>{`Code`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-mdx",
        "metastring": "path=components/Accordion/Accordion.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/Accordion",
        "path": "components/Accordion/Accordion.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/Accordion"
      }}>{`<Accordion>
  <AccordionItem title="Title 1">Content Section</AccordionItem>
  <AccordionItem title="Title 2">Content Section</AccordionItem>
  <AccordionItem title="Title 3">Content Section</AccordionItem>
</Accordion>
`}</code></pre>
    <h3>{`Props`}</h3>
    <Title mdxType="Title">Accordion</Title>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`propType`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Pass in the children that will be rendered within the Accordion`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specify an optional className to be applied to the container node`}</td>
        </tr>
      </tbody>
    </table>
    <Title mdxType="Title">AccordionItem</Title>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`propType`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`‘title’`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The accordion title`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`renderExpando`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`func`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`props => <button {…props} />`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The callback function to render the expando button. Can be a React component class.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`iconDescription`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`‘Expand/Collapse’`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The description of the expando icon`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`open`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`true`}</inlineCode>{` to open the expando`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`onClick`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`func`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`() => {}`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The handler of the massaged `}<inlineCode parentName="td">{`click`}</inlineCode>{` event.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`onHeadingClick`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`func`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`() => {}`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The handler of the massaged `}<inlineCode parentName="td">{`click`}</inlineCode>{` event on the heading.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Provide the contents of your AccordionItem`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specify an optional className to be applied to the container node`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      