import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`The `}<inlineCode parentName="p">{`<Title>`}</inlineCode>{` component is used to provide a title to a subsequent component
(table, image, video, code block). The `}<inlineCode parentName="p">{`Title`}</inlineCode>{` should be used in favor of other
techniques for bolded text (`}<inlineCode parentName="p">{`h4`}</inlineCode>{`s) to preserve page structure and heading
hierarchy.`}</p>
    </PageDescription>
    <h2>{`Example`}</h2>
    <Title mdxType="Title">This is a title</Title>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "56.25%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsSAAALEgHS3X78AAABuElEQVQoz32R20rrQBSG59ot4n4bxRfQR9HamkO9UbY3gqet3uiN6J1QBUVBPFWxxVJru22lamySmWQySRMPlRoFoTWl2U5jkVbFn5/FzGJ9a81iwP9mua5LY7lcVhqEEJJlmUbv4tkTcD01klS2bddLPZIQUigUNKwhiFTNS8JaC/ABvKtUKpmmKUlSI5mIJxiGSWZOdZOImRqJ80jBCNDSYrFYrVYrlQptT6sxxjT5DkMILcta21hrb/sdml4XFm8jQRIby2c2dekCAygjWYKEGBom9EDfdvYvvb93oKpqfUNNkcNSlDtJMlK8Hx0z+MhvhH03h0N3QDMQNSYQ61DRJPv5YYDz/WptyeVytSUpLaKrP+dZ/0WaF1K8eMKiGIujnHEQsMD+/BN12It7c/bRwsvS4PWEb7s+1lDRjiAGkpd8Ostm06yQYj2ewRHGAMu9lWY7oV43/NdBSKXfAxWkziShPy7yqSsu84kHK4zT6FXWWe573Rh9omPNeyt/ruh8BPMxyCVE7jMPQgGnyQMOnb819kLhjq7OkeDw8+SZFjj8lv8J7unuHp+detwU8n27ejD6lX8D9pP+2N+4DqEAAAAASUVORK5CYII=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Colors",
        "title": "Colors",
        "src": "/static/5737718955f2cba55b78b5e66b0cc215/3cbba/design-for-ai-art.png",
        "srcSet": ["/static/5737718955f2cba55b78b5e66b0cc215/7fc1e/design-for-ai-art.png 288w", "/static/5737718955f2cba55b78b5e66b0cc215/a5df1/design-for-ai-art.png 576w", "/static/5737718955f2cba55b78b5e66b0cc215/3cbba/design-for-ai-art.png 1152w", "/static/5737718955f2cba55b78b5e66b0cc215/0b124/design-for-ai-art.png 1728w", "/static/5737718955f2cba55b78b5e66b0cc215/20ee3/design-for-ai-art.png 1896w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <h2>{`Code`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-mdx",
        "metastring": "path=components/Title/Title.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/Title",
        "path": "components/Title/Title.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/Title"
      }}>{`<Title>This is a title</Title>

![Colors](images/design-for-ai-art.png)
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      