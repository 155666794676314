import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const InlineNotification = makeShortcode("InlineNotification");
const Title = makeShortcode("Title");
const CardGroup = makeShortcode("CardGroup");
const MiniCard = makeShortcode("MiniCard");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`The `}<inlineCode parentName="p">{`<MiniCard>`}</inlineCode>{` component can be used in place of a `}<inlineCode parentName="p">{`<ResourceCard>`}</inlineCode>{` if your
content allows it. Unless it is sitting aside your main conent, it should always
be wrapped inside of a `}<inlineCode parentName="p">{`<CardGroup>`}</inlineCode>{`. This allows the correct gutter and border
placement between a group of cards.`}</p>
    </PageDescription>
    <InlineNotification mdxType="InlineNotification">
  Although the mini-resource card has a similar geometry to the button
  component, they should not be used in place of a button. Buttons encourage
  action from the user and affect the website's front-end or back-end. The
  resource cards, both large and mini are essentially links. They are used for
  navigation and actions that do not affect the website.
    </InlineNotification>
    <h2>{`Example`}</h2>
    <Title mdxType="Title">Group</Title>
    <CardGroup mdxType="CardGroup">
      <MiniCard title="26 characters per MiniCard" href="/demo" mdxType="MiniCard">
        <img {...{
          "src": "/18f3a3dc6c5ae9221446f35a437b2780/adobe-icon.svg",
          "alt": "Adobe Acrobat icon"
        }}></img>
      </MiniCard>
      <MiniCard title="Use the default icon" href="/demo" mdxType="MiniCard" />
      <MiniCard title="Choose from other icons" href="/demo" actionIcon="arrowRight" mdxType="MiniCard" />
      <MiniCard title="Or bring your own" href="/demo" mdxType="MiniCard">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "95%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAAAsTAAALEwEAmpwYAAADaElEQVQ4y4WTT2xUdRDHv2/fKlRTjRBIvHIyUaPeNMYEEw8E8GRCYjggB9D+ldTyp0cxekBjoAFUojEkCmltt9228eABE02oVJLGthxoosFCZbu7bIuWuuzrm+E78163hYP+kslv3m/m+3kz83sPmgPEbAiBDAORboGMZzfIb+FTMtm4NR7HmzL9YodMv/ye+1ONr3psPLuxpptdI3kE2s+dlsCGkdFvAP0aG+RS+Es8lV2UyXXVeAIqU4+pzHXTTtJ/XP3MYpZzKRzTs9ik36YMA3qFPwSB9tDvQ79cDDW+so7CgML1KoVPY134MdKFC5EUjscy+YhYzHJkNFRq8jTIaAYrwNAO+NBJUxl7qBZPhbFMhCJ/HRXCRCp52mDi3/xQZCIrnsNctqnUdRnMWNC89/6SBdg+gWEcT3K/ccgASpBqZdjN/YULjHWJ5/yaEcmxE9P24xVjQSfQoAOYJl25L8tlBmfbRG//pDI/orLwPSGJmW9nHps9IJZrmlT7u17Go1g+h764Fxr3BjXb5eobordGRIvnVYo9qqUe39f6FmOOyvQuSbWR7WTlUenE6XI79Na7iMqtkOqXjaIjDSIDDaqDDSqDyb7W9xhz7n7VKOU2iGuN0YkzuPMJUGrGd6UWaPEd1OYP+zyEQxbpc9+G7pbOynyPzx+h5m3UTEvGwFI3Z3hzDzD/PrKlVoyyQodWP6NoiEAT5tLLsjnl0pcxVv2cEOaahtqxShceLrxFIB9CGkpt2MzgtVITlFVGXkkugcpqdeJnBLO6yHKpmaH+SWeQBZa7Cm3Fs0xYZJX67yne3lAdsrY6rZ7GsuUwd4ma553Rwi5bgATYvHrA4W6zN1cOOSBeAdXb55nN2atrx846rCnhWKvgYFHaex90v1d5ksChNZdD3yr36trRvFJIcTf3/a5Lljnlg7QD/pZMgXCKPuInZaDI5pbOM6oc9Js9Nrc3yS2ZtiMp7L5lBx5sR1DYBVx/HZjbh3NLx72ymtnSCcL2off6diB+xjWBzb78IKwOba1b8MdWYADIcJY/xz32N3Cuh3FxDMhee81bzaTz++9lwGJTIvhzG3BlC56onsEMbfbq09g4syOFJTm4+8X/ACsd6c3Tbh9F+M8x4E43nuNf8MLix8DfH/ATs4trSWb34LoHtkAcmzkyiKUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Github icon",
            "title": "Github icon",
            "src": "/static/28b17f4b368ca58d4d47e5136b85f0d3/59afc/sketch-icon.png",
            "srcSet": ["/static/28b17f4b368ca58d4d47e5136b85f0d3/59afc/sketch-icon.png 200w"],
            "sizes": "(max-width: 200px) 100vw, 200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </MiniCard>
    </CardGroup>
    <Title mdxType="Title">Aside</Title>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <p>{`When you have the mini resource card sitting aside the main content, be sure to
add the `}<inlineCode parentName="p">{`gutterLg`}</inlineCode>{`, properties to the `}<inlineCode parentName="p">{`<MiniCard>`}</inlineCode>{`. This will ensure the
MiniCard has the appropriate gutters at the approriate breakpoints.`}</p>
      </Column>
      <MiniCard gutterLg title="By itself, as an Aside" href="https://gatsby-theme-carbon.now.sh" mdxType="MiniCard">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "95%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAAAsTAAALEwEAmpwYAAADaElEQVQ4y4WTT2xUdRDHv2/fKlRTjRBIvHIyUaPeNMYEEw8E8GRCYjggB9D+ldTyp0cxekBjoAFUojEkCmltt9228eABE02oVJLGthxoosFCZbu7bIuWuuzrm+E78163hYP+kslv3m/m+3kz83sPmgPEbAiBDAORboGMZzfIb+FTMtm4NR7HmzL9YodMv/ye+1ONr3psPLuxpptdI3kE2s+dlsCGkdFvAP0aG+RS+Es8lV2UyXXVeAIqU4+pzHXTTtJ/XP3MYpZzKRzTs9ik36YMA3qFPwSB9tDvQ79cDDW+so7CgML1KoVPY134MdKFC5EUjscy+YhYzHJkNFRq8jTIaAYrwNAO+NBJUxl7qBZPhbFMhCJ/HRXCRCp52mDi3/xQZCIrnsNctqnUdRnMWNC89/6SBdg+gWEcT3K/ccgASpBqZdjN/YULjHWJ5/yaEcmxE9P24xVjQSfQoAOYJl25L8tlBmfbRG//pDI/orLwPSGJmW9nHps9IJZrmlT7u17Go1g+h764Fxr3BjXb5eobordGRIvnVYo9qqUe39f6FmOOyvQuSbWR7WTlUenE6XI79Na7iMqtkOqXjaIjDSIDDaqDDSqDyb7W9xhz7n7VKOU2iGuN0YkzuPMJUGrGd6UWaPEd1OYP+zyEQxbpc9+G7pbOynyPzx+h5m3UTEvGwFI3Z3hzDzD/PrKlVoyyQodWP6NoiEAT5tLLsjnl0pcxVv2cEOaahtqxShceLrxFIB9CGkpt2MzgtVITlFVGXkkugcpqdeJnBLO6yHKpmaH+SWeQBZa7Cm3Fs0xYZJX67yne3lAdsrY6rZ7GsuUwd4ma553Rwi5bgATYvHrA4W6zN1cOOSBeAdXb55nN2atrx846rCnhWKvgYFHaex90v1d5ksChNZdD3yr36trRvFJIcTf3/a5Lljnlg7QD/pZMgXCKPuInZaDI5pbOM6oc9Js9Nrc3yS2ZtiMp7L5lBx5sR1DYBVx/HZjbh3NLx72ymtnSCcL2off6diB+xjWBzb78IKwOba1b8MdWYADIcJY/xz32N3Cuh3FxDMhee81bzaTz++9lwGJTIvhzG3BlC56onsEMbfbq09g4syOFJTm4+8X/ACsd6c3Tbh9F+M8x4E43nuNf8MLix8DfH/ATs4trSWb34LoHtkAcmzkyiKUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Sketch icon",
            "title": "Sketch icon",
            "src": "/static/28b17f4b368ca58d4d47e5136b85f0d3/59afc/sketch-icon.png",
            "srcSet": ["/static/28b17f4b368ca58d4d47e5136b85f0d3/59afc/sketch-icon.png 200w"],
            "sizes": "(max-width: 200px) 100vw, 200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </MiniCard>
    </Row>
    <h2>{`Code`}</h2>
    <Title mdxType="Title">Group</Title>
    <pre><code parentName="pre" {...{
        "className": "language-mdx",
        "metastring": "path=components/MiniCard/MiniCard.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/MiniCard",
        "path": "components/MiniCard/MiniCard.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/MiniCard"
      }}>{`<CardGroup>
  <MiniCard title="26 characters per MiniCard" href="/demo">
    ![Adobe Acrobat icon](/images/adobe-icon.svg)
  </MiniCard>
  <MiniCard title="Use the default icon" href="/demo" />
  <MiniCard
    title="Choose from other icons"
    href="/demo"
    actionIcon="arrowRight"
  />
  <MiniCard title="Or bring your own" href="/demo">
    ![Github icon](/images/sketch-icon.png)
  </MiniCard>
</CardGroup>
`}</code></pre>
    <Title mdxType="Title">Aside</Title>
    <pre><code parentName="pre" {...{
        "className": "language-mdx",
        "metastring": "path=components/MiniCard/MiniCard.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/MiniCard",
        "path": "components/MiniCard/MiniCard.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/MiniCard"
      }}>{`<Row>
<Column colLg={8}>


When you have the mini resource card sitting aside the main content, be sure to
add the \`gutterLg\`, properties to the \`<MiniCard>\`. This will ensure the
MiniCard has the appropriate gutters at the approriate breakpoints.

</Column>
<MiniCard
  gutterLg
  title="By itself, as an Aside"
  href="https://gatsby-theme-carbon.now.sh"
  >


![Sketch icon](/images/sketch-icon.png)

</MiniCard>
</Row>

`}</code></pre>
    <h3>{`Props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`propType`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Use 32x32 image as child, will display in right-hand corner of the card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`href`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set url for card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Title for the card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`actionIcon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`launch`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Action icon, default is launch, options are `}<inlineCode parentName="td">{`launch`}</inlineCode>{`, `}<inlineCode parentName="td">{`arrowRight`}</inlineCode>{`, `}<inlineCode parentName="td">{`download`}</inlineCode>{`, `}<inlineCode parentName="td">{`disabled`}</inlineCode>{`, `}<inlineCode parentName="td">{`email`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Add custom class name`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      