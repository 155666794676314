// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-components-accordion-mdx": () => import("./../../../src/pages/components/Accordion.mdx" /* webpackChunkName: "component---src-pages-components-accordion-mdx" */),
  "component---src-pages-components-anchor-links-mdx": () => import("./../../../src/pages/components/AnchorLinks.mdx" /* webpackChunkName: "component---src-pages-components-anchor-links-mdx" */),
  "component---src-pages-components-art-direction-index-mdx": () => import("./../../../src/pages/components/ArtDirection/index.mdx" /* webpackChunkName: "component---src-pages-components-art-direction-index-mdx" */),
  "component---src-pages-components-article-card-mdx": () => import("./../../../src/pages/components/ArticleCard.mdx" /* webpackChunkName: "component---src-pages-components-article-card-mdx" */),
  "component---src-pages-components-aside-mdx": () => import("./../../../src/pages/components/Aside.mdx" /* webpackChunkName: "component---src-pages-components-aside-mdx" */),
  "component---src-pages-components-caption-mdx": () => import("./../../../src/pages/components/Caption.mdx" /* webpackChunkName: "component---src-pages-components-caption-mdx" */),
  "component---src-pages-components-code-blocks-mdx": () => import("./../../../src/pages/components/code-blocks.mdx" /* webpackChunkName: "component---src-pages-components-code-blocks-mdx" */),
  "component---src-pages-components-do-dont-example-mdx": () => import("./../../../src/pages/components/DoDontExample.mdx" /* webpackChunkName: "component---src-pages-components-do-dont-example-mdx" */),
  "component---src-pages-components-do-dont-row-mdx": () => import("./../../../src/pages/components/DoDontRow.mdx" /* webpackChunkName: "component---src-pages-components-do-dont-row-mdx" */),
  "component---src-pages-components-feature-card-mdx": () => import("./../../../src/pages/components/FeatureCard.mdx" /* webpackChunkName: "component---src-pages-components-feature-card-mdx" */),
  "component---src-pages-components-feedback-dialog-mdx": () => import("./../../../src/pages/components/FeedbackDialog.mdx" /* webpackChunkName: "component---src-pages-components-feedback-dialog-mdx" */),
  "component---src-pages-components-gif-player-mdx": () => import("./../../../src/pages/components/GifPlayer.mdx" /* webpackChunkName: "component---src-pages-components-gif-player-mdx" */),
  "component---src-pages-components-grid-mdx": () => import("./../../../src/pages/components/Grid.mdx" /* webpackChunkName: "component---src-pages-components-grid-mdx" */),
  "component---src-pages-components-image-card-mdx": () => import("./../../../src/pages/components/ImageCard.mdx" /* webpackChunkName: "component---src-pages-components-image-card-mdx" */),
  "component---src-pages-components-image-gallery-mdx": () => import("./../../../src/pages/components/ImageGallery.mdx" /* webpackChunkName: "component---src-pages-components-image-gallery-mdx" */),
  "component---src-pages-components-inline-notification-mdx": () => import("./../../../src/pages/components/InlineNotification.mdx" /* webpackChunkName: "component---src-pages-components-inline-notification-mdx" */),
  "component---src-pages-components-markdown-mdx": () => import("./../../../src/pages/components/markdown.mdx" /* webpackChunkName: "component---src-pages-components-markdown-mdx" */),
  "component---src-pages-components-medium-posts-mdx": () => import("./../../../src/pages/components/MediumPosts.mdx" /* webpackChunkName: "component---src-pages-components-medium-posts-mdx" */),
  "component---src-pages-components-mini-card-mdx": () => import("./../../../src/pages/components/MiniCard.mdx" /* webpackChunkName: "component---src-pages-components-mini-card-mdx" */),
  "component---src-pages-components-page-description-mdx": () => import("./../../../src/pages/components/PageDescription.mdx" /* webpackChunkName: "component---src-pages-components-page-description-mdx" */),
  "component---src-pages-components-resource-card-mdx": () => import("./../../../src/pages/components/ResourceCard.mdx" /* webpackChunkName: "component---src-pages-components-resource-card-mdx" */),
  "component---src-pages-components-tabs-mdx": () => import("./../../../src/pages/components/Tabs.mdx" /* webpackChunkName: "component---src-pages-components-tabs-mdx" */),
  "component---src-pages-components-title-mdx": () => import("./../../../src/pages/components/Title.mdx" /* webpackChunkName: "component---src-pages-components-title-mdx" */),
  "component---src-pages-components-video-index-mdx": () => import("./../../../src/pages/components/Video/index.mdx" /* webpackChunkName: "component---src-pages-components-video-index-mdx" */),
  "component---src-pages-contributions-mdx": () => import("./../../../src/pages/contributions.mdx" /* webpackChunkName: "component---src-pages-contributions-mdx" */),
  "component---src-pages-demo-index-mdx": () => import("./../../../src/pages/demo/index.mdx" /* webpackChunkName: "component---src-pages-demo-index-mdx" */),
  "component---src-pages-gallery-index-mdx": () => import("./../../../src/pages/gallery/index.mdx" /* webpackChunkName: "component---src-pages-gallery-index-mdx" */),
  "component---src-pages-getting-started-index-mdx": () => import("./../../../src/pages/getting-started/index.mdx" /* webpackChunkName: "component---src-pages-getting-started-index-mdx" */),
  "component---src-pages-guides-configuration-mdx": () => import("./../../../src/pages/guides/configuration.mdx" /* webpackChunkName: "component---src-pages-guides-configuration-mdx" */),
  "component---src-pages-guides-hosting-mdx": () => import("./../../../src/pages/guides/hosting.mdx" /* webpackChunkName: "component---src-pages-guides-hosting-mdx" */),
  "component---src-pages-guides-mdx-mdx": () => import("./../../../src/pages/guides/MDX.mdx" /* webpackChunkName: "component---src-pages-guides-mdx-mdx" */),
  "component---src-pages-guides-navigation-sidebar-mdx": () => import("./../../../src/pages/guides/navigation/sidebar.mdx" /* webpackChunkName: "component---src-pages-guides-navigation-sidebar-mdx" */),
  "component---src-pages-guides-navigation-tabs-mdx": () => import("./../../../src/pages/guides/navigation/tabs.mdx" /* webpackChunkName: "component---src-pages-guides-navigation-tabs-mdx" */),
  "component---src-pages-guides-npm-scripts-mdx": () => import("./../../../src/pages/guides/npm-scripts.mdx" /* webpackChunkName: "component---src-pages-guides-npm-scripts-mdx" */),
  "component---src-pages-guides-shadowing-mdx": () => import("./../../../src/pages/guides/shadowing.mdx" /* webpackChunkName: "component---src-pages-guides-shadowing-mdx" */),
  "component---src-pages-guides-styling-mdx": () => import("./../../../src/pages/guides/styling.mdx" /* webpackChunkName: "component---src-pages-guides-styling-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-test-spacing-audit-mdx": () => import("./../../../src/pages/test/spacing-audit.mdx" /* webpackChunkName: "component---src-pages-test-spacing-audit-mdx" */)
}

