import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import HomepageTemplate from 'gatsby-theme-carbon/src/templates/Homepage';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const FeatureCard = makeShortcode("FeatureCard");
const ArtDirection = makeShortcode("ArtDirection");
const layoutProps = {
  _frontmatter
};
const MDXLayout = HomepageTemplate;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h2>{`MDX content starts here`}</h2>
    <PageDescription mdxType="PageDescription">
      <p>{`The homepage content here lives in the `}<inlineCode parentName="p">{`src/pages/index.mdx`}</inlineCode>{` directory at the
root of your project. Just like the other mdx pages, you can use all of our
theme components here without importing them. Review the
`}<a parentName="p" {...{
          "href": "https://github.com/carbon-design-system/gatsby-theme-carbon/blob/main/packages/example/src/pages/index.mdx"
        }}>{`MDX example source`}</a>{`.`}</p>
    </PageDescription>
    <FeatureCard color="dark" href="/getting-started" title="Getting started" actionIcon="arrowRight" className="homepage-feature" mdxType="FeatureCard">
      <ArtDirection mdxType="ArtDirection">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAABQD/xAAVAQEBAAAAAAAAAAAAAAAAAAADBP/aAAwDAQACEAMQAAABHeBesBiqN//EABcQAAMBAAAAAAAAAAAAAAAAAAABAzH/2gAIAQEAAQUCmQFkyAs//8QAFhEAAwAAAAAAAAAAAAAAAAAAAhAy/9oACAEDAQE/ASpf/8QAFhEAAwAAAAAAAAAAAAAAAAAAARAz/9oACAECAQE/ARNf/8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAFhABAQEAAAAAAAAAAAAAAAAAQQAQ/9oACAEBAAE/IRHARw//2gAMAwEAAgADAAAAEKPv/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAEx/9oACAEDAQE/EMUf/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAEx/9oACAECAQE/EFdf/8QAFhABAQEAAAAAAAAAAAAAAAAAMQAQ/9oACAEBAAE/EFhKWEr/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "blue vertical stripes",
            "title": "blue vertical stripes",
            "src": "/static/2ab1550f185f6b7e782e1aa3eeeb3046/2e753/blue-stripes-mobile.jpg",
            "srcSet": ["/static/2ab1550f185f6b7e782e1aa3eeeb3046/69549/blue-stripes-mobile.jpg 288w", "/static/2ab1550f185f6b7e782e1aa3eeeb3046/473e3/blue-stripes-mobile.jpg 576w", "/static/2ab1550f185f6b7e782e1aa3eeeb3046/2e753/blue-stripes-mobile.jpg 1152w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAABQD/xAAVAQEBAAAAAAAAAAAAAAAAAAADBP/aAAwDAQACEAMQAAABHeBesBiqN//EABcQAAMBAAAAAAAAAAAAAAAAAAABAzH/2gAIAQEAAQUCmQFkyAs//8QAFhEAAwAAAAAAAAAAAAAAAAAAAhAy/9oACAEDAQE/ASpf/8QAFhEAAwAAAAAAAAAAAAAAAAAAARAz/9oACAECAQE/ARNf/8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAFhABAQEAAAAAAAAAAAAAAAAAQQAQ/9oACAEBAAE/IRHARw//2gAMAwEAAgADAAAAEKPv/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAEx/9oACAEDAQE/EMUf/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAEx/9oACAECAQE/EFdf/8QAFhABAQEAAAAAAAAAAAAAAAAAMQAQ/9oACAEBAAE/EFhKWEr/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "blue vertical stripes",
            "title": "blue vertical stripes",
            "src": "/static/2ab1550f185f6b7e782e1aa3eeeb3046/2e753/blue-stripes-tablet.jpg",
            "srcSet": ["/static/2ab1550f185f6b7e782e1aa3eeeb3046/69549/blue-stripes-tablet.jpg 288w", "/static/2ab1550f185f6b7e782e1aa3eeeb3046/473e3/blue-stripes-tablet.jpg 576w", "/static/2ab1550f185f6b7e782e1aa3eeeb3046/2e753/blue-stripes-tablet.jpg 1152w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAHABQDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAX/xAAUAQEAAAAAAAAAAAAAAAAAAAAE/9oADAMBAAIQAxAAAAGRdGnsASP/xAAYEAACAwAAAAAAAAAAAAAAAAAAAQIDMf/aAAgBAQABBQKBQLP/xAAWEQADAAAAAAAAAAAAAAAAAAABEDL/2gAIAQMBAT8BNL//xAAVEQEBAAAAAAAAAAAAAAAAAAAQQ//aAAgBAgEBPwGZ/8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQAGPwJ//8QAGBAAAgMAAAAAAAAAAAAAAAAAAAEQMUH/2gAIAQEAAT8hujEH/9oADAMBAAIAAwAAABB37//EABURAQEAAAAAAAAAAAAAAAAAAAAB/9oACAEDAQE/EHH/xAAWEQEBAQAAAAAAAAAAAAAAAAAAATH/2gAIAQIBAT8Qhdf/xAAWEAADAAAAAAAAAAAAAAAAAAAAEDH/2gAIAQEAAT8QU5H/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "blue vertical stripes",
            "title": "blue vertical stripes",
            "src": "/static/9820c2d87efd52c02784eeff51e351b2/2e753/blue-stripes.jpg",
            "srcSet": ["/static/9820c2d87efd52c02784eeff51e351b2/69549/blue-stripes.jpg 288w", "/static/9820c2d87efd52c02784eeff51e351b2/473e3/blue-stripes.jpg 576w", "/static/9820c2d87efd52c02784eeff51e351b2/2e753/blue-stripes.jpg 1152w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </ArtDirection>
    </FeatureCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      