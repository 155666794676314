import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`One of the most important pieces of making a working site is to define your
navigation items. This allows the theme to inform the SideNav component as well
as the next/previous components at the bottom of each page.`}</p>
    </PageDescription>
    <h2>{`Yaml data`}</h2>
    <p>{`Unfortunately, genenerating left nav contents purely from the pages directory
has some fatal flaws. It makes it very difficult to establish the order of items
or to hide items all together. In `}<inlineCode parentName="p">{`src/data/nav-items.yaml`}</inlineCode>{`, you’ll list your
nav items in order.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`- title: Menu
  pages:
    - title: Page 1
      path: /menu/Page-1
    - title: Page 2
      path: /menu/Page-2
- title: Single Page
  pages:
    - path: /single-page
`}</code></pre>
    <p>{`Some important things to note here:`}</p>
    <ul>
      <li parentName="ul">{`You only need to link to the first tab if using page tabs`}</li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`title`}</inlineCode>{` refers to the menu and item text, it’s allowed to have spaces`}</li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`path`}</inlineCode>{` refer to the relative path to the mdx file in your pages`}</li>
      <li parentName="ul">{`You can make a `}<inlineCode parentName="li">{`Page/index.mdx`}</inlineCode>{` file if you’d prefer to have assets in a
folder. The path would still just look like `}<inlineCode parentName="li">{`/Page`}</inlineCode></li>
    </ul>
    <h2>{`Adding a divider`}</h2>
    <p>{`You can insert a divider below any top-level nav item by adding the
`}<inlineCode parentName="p">{`hasDivider: true`}</inlineCode>{` property to it’s configuration under
`}<inlineCode parentName="p">{`src/data/nav-items.yaml`}</inlineCode>{`. `}<strong parentName="p">{`Note:`}</strong>{` dividers can only be use with top-level nav
items and are incompatible with the
`}<a parentName="p" {...{
        "href": "/guides/configuration#navigation-style"
      }}>{`header navigation style`}</a>{`.`}</p>
    <p>{`When shadowed, the `}<inlineCode parentName="p">{`ResourceLinks`}</inlineCode>{` component can optionally receive an
`}<inlineCode parentName="p">{`includeDividerSpace`}</inlineCode>{` prop. Set this to false if you want to remove the margin
above and below the `}<inlineCode parentName="p">{`ResourceLinks`}</inlineCode>{` divider.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`- title: Menu
  pages:
    - title: Page 1
      path: /menu/Page-1
    - title: Page 2
      path: /menu/Page-2
  hasDivider: true
- title: Single Page
  pages:
    - path: /single-page
`}</code></pre>
    <p>{`In the example above, a divider will appear between `}<strong parentName="p">{`Menu`}</strong>{` and `}<strong parentName="p">{`Single
Page`}</strong>{`.`}</p>
    <h2>{`Customizing`}</h2>
    <p>{`The nav item list can be customized using Gatsby theme
`}<a parentName="p" {...{
        "href": "/guides/shadowing"
      }}>{`shadowing`}</a>{`. Simply provide your own implementation of
`}<inlineCode parentName="p">{`/src/util/NavItems.js`}</inlineCode>{` which can augment or replace the nav items read from
`}<inlineCode parentName="p">{`src/data/nav-items.yaml`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// /src/util/NavItems.js
import { useNavItems as themeUseNavItems } from 'gatsby-theme-carbon/src/util/NavItems';

// add nav items
export function useNavItems() {
  const navItems = themeUseNavItems();
  return navItems.concat({
    title: 'Additional Nav Item',
    pages: [
      { path: '/page1', title: 'New Page 1' },
      { path: '/page2', title: 'New Page 2' },
    ],
  });
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      